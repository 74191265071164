img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  vertical-align: unset;
}
