td {
  text-align: left !important;
}

td + td {
  text-align: right !important;
}

tr {
  text-align: center !important;
}

.custom-toggle-button {
  .btn-outline-dark {
    &:focus {
      box-shadow: none !important;
    }

    &:hover {
      color: inherit;
      background-color: inherit;
      border-color: inherit;
      box-shadow: none;
    }

    &:active {
      color: inherit;
      background-color: rgba(92, 92, 92, 0.1) !important;
      box-shadow: rgba(92, 92, 92, 0.8) 0px 0px 0px 2px inset !important;
    }
  }

  .btn-check:checked + .btn-outline-dark {
    animation: blinker 0.2s linear 2;
    color: inherit;
    background-color: rgba(92, 92, 92, 0.1) !important;
    box-shadow: rgba(92, 92, 92, 0.8) 0px 0px 0px 2px inset !important;
  }
}

.pressed-btn {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

  &:active {
    opacity: 0.8;
    box-shadow: none;
    transform: translateY(2px);
  }

  &:focus {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.styled-input {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;

  &:focus-visible {
    outline: none;
  }
}
